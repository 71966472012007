<template>
  <div>
    <vs-card>
      <vs-table ref="table" max-items="20" search :data="projects" pagination>
        <template slot="thead">
          <vs-th>Código</vs-th>
          <vs-th>Descrição</vs-th>
          <vs-th>Valor SAP</vs-th>
          <vs-th>Valor Estimado</vs-th>
          <vs-th>Unidade(s) Homologada(s)</vs-th>
          <vs-th>Imagem</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.code }}
              </vs-td>
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                <span v-if="tr.sapValue"
                  >R$ {{ Number(tr.sapValue).toFixed(2) }} </span
                ><span v-else
                  >R$ {{ Number(tr.externalPurchaseValue).toFixed(2) }}</span
                ></vs-td
              >
              <vs-td>
                <span v-if="tr.estimatedValue"
                  >R$ {{ Number(tr.estimatedValue).toFixed(2) }}</span
                >
              </vs-td>
              <vs-td>
                {{ getHomologationUnits(indextr) }}
              </vs-td>
              <vs-td>
                <vs-button
                  @click="showScreenImage(tr.image)"
                  type="flat"
                  icon="photo_camera"
                  closable
                  v-if="tr.image"
                ></vs-button
              ></vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-popup :active.sync="showImage" title="Imagem do Projeto" fullscreen>
        <img :src="image" title="Imagem do Projeto" />
      </vs-popup>
    </vs-card>
  </div>
</template>

  <script>
import siriusAPI from "../services";
import { notifyError } from "@/utils/helpers";
export default {
  data() {
    return {
      projects: [],
      showImage: false,
      image: null,
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    showScreenImage(image) {
      this.showImage = true;
      this.image = process.env.VUE_APP_URL_IMAGES + image;
    },
    loadProjects() {
      this.$vs.loading();
      new siriusAPI()
        .getProjects()
        .then((res) => {
          this.projects = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de projetos " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getHomologationUnits(index) {
      let units = "";
      for (let i = 0; i < this.projects[index].homologationUnits.length; i++) {
        units = units + this.projects[index].homologationUnits[i].unit.name;
        if (i + 1 < this.projects[index].homologationUnits.length)
          units = units + ", ";
      }
      if (units == "") units = "-";
      return units;
    },
  },
};
</script>
  <style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
</style>
